body {

    &[data-menue-aktiv="true"] {
        position: fixed;
        width: 100%;

        // Bedeckt das Menü nur einen Teil der Seite, ist also ein Teil der Seite weiterhin zu sehen,
        // soll die Maus Interaktionen ausserhalb des Menüs ignorieren
        .main {
            pointer-events: none;
        }
    }

}