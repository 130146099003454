.seite {
    
    position: relative;
    
}

@include mediaquery(smartphone) {
    $layout: smartphone;

    .seite {

        width: 100%;
        height: 100%;

    }

}


@include mediaquery(tabletAb) {
    $layout: tablet;


}


@include mediaquery(tablet) {
    $layout: tablet;


}


@include mediaquery(desktopAb) {
    $layout: desktop;

    .seite {

        

    }

}