.shop {
    &__produkt-container {
        background-color: lighten(map-get($farben, 'fleischlinGruen'), 17);
    }

    &__titel {
        font-weight: 700;
    }

    &__kategorie {
        color: map-get($farben, 'fleischlinGruen');
        font-weight: 700;
    }

    &__anzahl {
        display: flex;
        align-items: center;
    }

    &__anzahl-knopf {
        align-items: center;
        background-color: white;
        border: 1px solid black;
        display: grid;
        justify-content: center;
        padding: 0;

        &:hover {
            background-color: map-get($farben, 'fleischlinGruen');

            &::after,
            &::before {
                background-color: white;
                cursor: pointer;
            }
        }

        &::after,
        &::before {
            background-color: black;
            content: "";
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }

        &--plus {
            &::after {
                transform: rotate(90deg);
            }
        }

    }

    &__anzahl-anzahl {
        background-color: transparent;
        border: none;
        font-family: 'Source Serif Pro', serif;
        font-weight: 700;
        text-align: center;
    }

    // Automatische Höhe für die Bestellung textarea
    // https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
    .fui-field[data-field-handle="bestellung"] {
        & .fui-input-container {
            /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
            display: grid;
        }

        & .fui-input-container::after {
            /* Note the weird space! Needed to preventy jumpy behavior */
            content: attr(data-replicated-value) " ";
    
            /* This is how textarea text behaves */
            white-space: pre-wrap;
    
            /* Hidden from view, clicks, and screen readers */
            visibility: hidden;
        }
        
        & textarea {
            /* You could leave this, but after a user resizes, then it ruins the auto sizing */
            resize: none;
    
            /* Firefox shows scrollbar on growth, you can hide like this. */
            overflow: hidden;
        }
    
        & textarea,
        & .fui-input-container::after {
            /* Identical styling required!! */
            border: none;
            color: black;
            padding: 0;
            font-weight: 700;
    
            /* Place on top of each other */
            grid-area: 1 / 1 / 2 / 2;
        }
    }

    &__recaptcha {      
        a {
            color: black;
        }
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .shop {
        &__produkte {
            @include abstand($layout, margin, bottom, 145);
            @include abstand($layout, margin, alle, 0, 0, 145, -7.5);
        }

        &__produkt-breite {
            width: 50%;
        }

        &__produkt {
            @include abstand($layout, padding, alle, 0, 7, 14, 7);
            @include schriftart($layout, 16, 18);
            width: 50%;
        }

        &__produkt-container {
            @include abstand($layout, padding, alle, 0, 7.5, 7.5, 7.5);
        }

        &__bild {
            @include abstand($layout, margin, alle, 0, -7, 7, -7);
        }

        &__titel {
            @include abstand($layout, margin, bottom, 12);
            @include schriftart($layout, 16, 18);
        }

        &__beschreibung {
            p {
                @include abstand($layout, margin, bottom, 12)
            }
        }

        &__anzahl {
            // Minus margin-bottom von &__beschreibung p
            @include abstand($layout, margin, top, 30 - 12)
        }

        &__anzahl-knopf {
            height: groesse($layout, 22);
            width: groesse($layout, 22);

            &::after,
            &::before {
                height: groesse($layout, 2);
                width: groesse($layout, 13);
            }
        }

        &__anzahl-anzahl {
            @include abstand($layout, margin, 0, 8);
            @include schriftart($layout, 16, 18);
            max-width: groesse($layout, 54);
            min-width: 18px;
        }

        &__anzahl-wort {
            @include abstand($layout, padding, left, 16)
        }

        .fui-field[data-field-handle="bestellung"] {
            & textarea,
            & .fui-input-container::after {
                font-size: groesse($layout, 20);
                line-height: groesse($layout, 24);
            }
        }

        &__recaptcha {
            font-size: groesse($layout, 20);
            margin-top: groesse($layout, 45);
        }
    }
}


@include mediaquery(desktop) {
    $layout: desktop;

    .shop {
        &__produkte {
            @include abstand($layout, margin, alle, 0, 0, 170, -22.5);
            width: spalten($layout, 9, 12, 0, 1);
        }

        &__produkt-breite {
            width: 33.3%;
        }

        &__produkt {
            @include abstand($layout, padding, alle, 0, 22.5, 45, 22.5);
            @include schriftart($layout, 24, 28);
            width: 33.33%;
        }

        &__produkt-container {
            @include abstand($layout, padding, alle, 0, 15, 15, 15);
        }

        &__bild {
            @include abstand($layout, margin, alle, 0, -15, 15, -15);
        }

        &__titel {
            @include abstand($layout, margin, bottom, 16);
            @include schriftart($layout, 24, 28);
        }

        &__beschreibung {
            p {
                @include abstand($layout, margin, bottom, 16)
            }
        }

        &__anzahl {
            // Minus margin-bottom von &__beschreibung p
            @include abstand($layout, margin, top, 50 - 16)
        }

        &__anzahl-knopf {
            height: groesse($layout, 40);
            width: groesse($layout, 40);

            &::after,
            &::before {
                height: groesse($layout, 3);
                width: groesse($layout, 23);
            }
        }

        &__anzahl-anzahl {
            @include schriftart($layout, 24, 28);
            width: groesse($layout, 92);
        }

        &__anzahl-wort {
            @include abstand($layout, padding, left, 16)
        }

        .fui-field[data-field-handle="bestellung"] {
            & textarea,
            & .fui-input-container::after {
                font-size: groesse($layout, 24);
                line-height: groesse($layout, 28);
            }
        }

        &__recaptcha {
            font-size: groesse($layout, 20);
            margin-top: groesse($layout, 88);
            width: spalten($layout, 6, 12, 0, 0);
        }
    }
}