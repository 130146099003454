// padding-bottom Trick für Bilder
.macro-bild {
    position: relative;
    display: block;
    height: 0;
    width: 100%;
    
    // Layzload Bilder erst nach dem Laden einblenden
    .lazyload,
    .lazyloading {
        opacity: 0;
    }

    .lazyloaded {
        transition: background-color 500ms ease-in;
        opacity: 1;
        background-color: transparent;
        /*  transition is applied to lazyloaded not lazyload */
        transition: opacity 500ms ease-in;
    }
}

.macro-bild__img,
.macro-bild__picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}