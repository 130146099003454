.footer {
    background-color: white;

    &__link {
        color: black !important;
        text-decoration: none;
        
        &:hover {
            color: black !important;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .footer {
        @include abstand($layout, padding, alle, 45, 22, 22, 22);
        @include abstand($layout, margin, bottom, 140);
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .footer {
        @include abstand($layout, margin, alle, 0, 25, 25, 200);
        @include abstand($layout, padding, alle, (265 - 45), (375 - 25), 15, 200);
        @include abstand($layout, margin, bottom, 25);
    }

}