@charset "UTF-8";
.bild {
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  text-decoration-skip-ink: auto;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Source Serif Pro", serif;
}
body.preload * {
  transition: transform 0s !important;
}
body[data-site=andreasFleischlin] {
  background-color: #C1D3C1;
}
body[data-site=wiederInsLeben] {
  background-color: #BFDBDE;
}
body[data-site=bleibenOderGehen] {
  background-color: #EF9362;
}

#seite:after {
  background-color: white;
  content: "";
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
  position: relative;
}
li::before {
  content: "–";
  left: 0;
  position: absolute;
}

svg {
  padding: 1px 1px 1px 1px;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

*:focus-visible {
  outline: 1px solid;
}

.bild-verhaeltnis {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.bild-verhaeltnis.lazyloaded {
  background-color: white;
  transition: background-color 500ms ease-in;
}

.bild-verhaeltnis img,
.bild-verhaeltnis picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

img {
  color: transparent;
  display: block;
  line-height: 1em;
  text-indent: -9999px;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  /*  transition is applied to lazyloaded, not to lazyload */
  transition: opacity 500ms ease-in;
}

.abschnitt__strich {
  display: block;
}
.abschnitt:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 799px) {
  .body[data-site=andreasFleischlin] .abschnitt {
    margin-bottom: 145px;
  }
  .abschnitt__titel {
    margin: 30px 0px 45px 0px;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
  }
  .abschnitt__strich {
    padding: 45px 0px 0px 0px;
    width: 55px;
    height: 70px;
  }
}
@media (min-width: 800px) {
  .abschnitt {
    margin: 0vw 0vw 11.55vw 0vw;
  }
  .abschnitt__titel {
    margin: 2.27vw 0vw 2.27vw 0vw;
    font-size: 2.27vw;
    line-height: 2.53vw;
    font-weight: 700;
  }
  .abschnitt__strich {
    margin: 0vw 0vw 2.27vw 0vw;
    padding: 2.27vw 0vw 0vw 0vw;
    width: 8.98vw;
    height: 5.45vw;
  }
}
.aktuell a {
  color: var(--abschnittsfarbe);
  text-decoration: none;
  font-weight: 700;
}
.aktuell a:hover {
  color: black;
}
.aktuell__bild {
  width: 75%;
}

@media (max-width: 799px) {
  .aktuell {
    display: flex;
    flex-direction: column;
  }
  .aktuell__intro {
    margin: 135px 0px 45px 0px;
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    color: #C1D3C1;
    width: 100%;
  }
  .aktuell__strich {
    width: 57.3px;
    height: 28.65px;
  }
  .aktuell__strich-svg--smartphone {
    display: block;
  }
  .aktuell__strich-svg--desktop {
    display: none;
  }
  .aktuell__container {
    margin: 0px 0px 45px 0px;
  }
  .aktuell__titel {
    margin: 7px 0px 15px 0px;
    font-size: 28px;
    line-height: 28px;
    font-weight: 700;
  }
  .aktuell__text {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
  }
}
@media (min-width: 800px) {
  .aktuell {
    display: flex;
    flex-wrap: wrap;
  }
  .aktuell__intro {
    margin: 10.68vw 0vw 2.83vw 0vw;
    font-size: 2.27vw;
    line-height: 2.27vw;
    font-weight: 700;
    color: #C1D3C1;
    width: 100%;
  }
  .aktuell__spaltenabstand {
    width: 3.49vw;
  }
  .aktuell__strich {
    width: 4.66vw;
    height: 2.27vw;
  }
  .aktuell__strich-svg--smartphone {
    display: none;
  }
  .aktuell__strich-svg--desktop {
    display: block;
  }
  .aktuell__container {
    width: calc(((100% - 21.56vw) / 12 * 3.35 + 6.566vw) * 1);
  }
  .aktuell__titel {
    margin: 0.13vw 0vw 0.65vw 0vw;
    font-size: 1.57vw;
    line-height: 1.66vw;
    font-weight: 700;
  }
  .aktuell__text {
    margin-bottom: 1.09vw;
    font-size: 0.87vw;
    line-height: 1.13vw;
    font-weight: 300;
  }
}
.angebot__angebot {
  display: flex;
  flex-direction: column;
}
.angebot__ecke {
  width: 100%;
}
.angebot__inhalt {
  flex: 1;
}
body[data-site=andreasFleischlin] .angebot__inhalt {
  background-color: #F0F3EE;
}
body[data-site=wiederInsLeben] .angebot__inhalt {
  background-color: #BFDBDE;
}
body[data-site=bleibenOderGehen] .angebot__inhalt {
  background-color: #FAD9C8;
}
body[data-site=wiederInsLeben] .angebot__titel {
  color: white;
}
body[data-site=andreasFleischlin] .angebot__titel {
  color: black;
}
body[data-site=wiederInsLeben] .angebot__details {
  color: #2A8591;
}
body[data-site=bleibenOderGehen] .angebot__details {
  color: #EF9362;
}
.angebot__beschreibung {
  hyphens: auto;
}
.angebot__beschreibung p:first-child,
.angebot__beschreibung ul:first-child {
  margin-top: 0;
}
.angebot__beschreibung p:last-child,
.angebot__beschreibung ul:last-child {
  margin-bottom: 0;
}
.angebot__beschreibung a {
  font-weight: 700;
  text-decoration: none;
}
body[data-site=wiederInsLeben] .angebot__beschreibung a {
  color: #2A8591;
}
body[data-site=bleibenOderGehen] .angebot__beschreibung a {
  color: #EF9362;
}
body[data-site=andreasFleischlin] .angebot__beschreibung a {
  color: var(--abschnittsfarbe);
}
.angebot__beschreibung a:hover {
  color: black;
}
.angebot__termin-anfragen {
  background-color: transparent;
  border: none;
  font-family: "Source Serif Pro", serif;
  text-align: left;
}
.angebot__termin-anfragen-link {
  text-decoration: none;
}
body[data-site=wiederInsLeben] .angebot__termin-anfragen-link {
  color: #2A8591;
}
body[data-site=wiederInsLeben] .angebot__termin-anfragen-link:hover {
  color: black;
  fill: black;
}
body[data-site=wiederInsLeben] .angebot__termin-anfragen-link:hover .button__pfeil {
  color: black;
  fill: black;
}
body[data-site=bleibenOderGehen] .angebot__termin-anfragen-link {
  color: #EF9362;
}
body[data-site=bleibenOderGehen] .angebot__termin-anfragen-link:hover {
  color: black;
  fill: black;
}
body[data-site=bleibenOderGehen] .angebot__termin-anfragen-link:hover .button__pfeil {
  color: black;
  fill: black;
}
body[data-site=andreasFleischlin] .angebot__termin-anfragen-link:hover {
  color: black;
  fill: black;
}
body[data-site=andreasFleischlin] .angebot__termin-anfragen-link:hover .button__pfeil {
  color: black;
  fill: black;
}

@media (max-width: 799px) {
  .angebot {
    margin-top: calc(35% + 22px);
  }
  .angebot__angebot {
    margin-top: calc(-35% + 22px);
  }
  .angebot__inhalt {
    padding: 45px 15px 45px 15px;
  }
  .angebot__titel {
    margin: 0px 0px 45px 0px;
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
  }
  .angebot__art {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }
  .angebot__details {
    margin: 0px 0px 45px 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }
  .angebot__beschreibung {
    margin: 45px 0px 0px 0px;
    font-size: 20px;
    line-height: 24px;
  }
  .angebot__beschreibung p {
    margin: 24px 0px 24px 0px;
  }
  .angebot__beschreibung ul {
    margin: 24px 0px 24px 0px;
  }
  .angebot__beschreibung li {
    padding-left: 25px;
  }
  .angebot__beschreibung li::before {
    top: -1px;
  }
  .angebot__termin-anfragen {
    margin: 45px 0px 0px 0px;
  }
  .angebot__termin-anfragen-link {
    font-size: 32px;
    line-height: 34px;
    font-weight: bold;
  }
  .angebot__pfeil {
    height: 17px;
    width: 35px;
  }
}
@media (min-width: 800px) {
  .angebot {
    display: flex;
    flex-wrap: wrap;
    margin: 0vw -0.98vw 0vw -0.98vw;
    margin-top: calc(10.5% + 5.23vw);
  }
  .angebot__angebot {
    padding: 0vw 0.98vw 0vw 0.98vw;
    margin-top: calc(-10.5% + 1.96vw);
    width: 33.3333333333%;
  }
  .angebot__inhalt {
    padding: 3.05vw 0.87vw 2.27vw 0.87vw;
  }
  .angebot__titel {
    margin: 0vw 0vw 2.27vw 0vw;
    font-size: 2.27vw;
    line-height: 2.53vw;
    font-weight: bold;
  }
  .angebot__art {
    font-size: 1.05vw;
    line-height: 1.22vw;
    font-weight: bold;
  }
  .angebot__details {
    margin: 0vw 0vw 2.27vw 0vw;
    font-size: 1.05vw;
    line-height: 1.22vw;
    font-weight: bold;
  }
  .angebot__beschreibung {
    margin: 2.27vw 0vw 0vw 0vw;
    font-size: 0.87vw;
    line-height: 1.22vw;
  }
  .angebot__beschreibung p {
    margin: 1.22vw 0vw 1.22vw 0vw;
  }
  .angebot__beschreibung ul {
    margin: 1.22vw 0vw 1.22vw 0vw;
  }
  .angebot__beschreibung li {
    padding-left: 1.09vw;
  }
  .angebot__beschreibung li::before {
    top: -0.04vw;
  }
  .angebot__termin-anfragen {
    margin: 2.27vw 0vw 0vw 0vw;
  }
  .angebot__termin-anfragen-link {
    font-size: 1.39vw;
    line-height: 1.48vw;
    font-weight: bold;
  }
  .angebot__pfeil {
    height: 0.74vw;
    width: 1.53vw;
  }
}
.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: "Source Serif Pro", serif;
  padding: 0;
  text-align: left;
  text-decoration: none;
}
.button:hover {
  color: black;
  fill: black;
}
body[data-site=wiederInsLeben] .button__pfeil {
  fill: #2A8591;
}
body[data-site=bleibenOderGehen] .button__pfeil {
  fill: #EF9362;
}

@media (max-width: 799px) {
  .button {
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
  }
  .button__pfeil {
    height: 17px;
    width: 35px;
  }
}
@media (min-width: 800px) {
  .button {
    font-size: 1.39vw;
    line-height: 1.48vw;
    font-weight: 700;
  }
  .button__pfeil {
    height: 0.74vw;
    width: 1.53vw;
  }
}
.call-to-action-button {
  font-weight: 700;
  position: relative;
}
.call-to-action-button__button {
  background-color: var(--abschnittsfarbe);
  color: #fff;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.call-to-action-button__button:hover {
  background-color: black;
}
.call-to-action-button__svg {
  fill: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 799px) {
  .call-to-action-button {
    margin: -10px 0px 40px 0px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }
  .call-to-action-button__button {
    padding: 8px 47px 7px 15px;
    border-radius: 24px;
  }
  .call-to-action-button__svg {
    height: 13px;
    width: 28px;
    right: 11px;
  }
}
@media (min-width: 800px) {
  .call-to-action-button {
    margin: -1.22vw 0vw 3.83vw 0vw;
    font-size: 1.39vw;
    line-height: 1.48vw;
  }
  .call-to-action-button__button {
    padding: 0.52vw 3.27vw 0.44vw 1.09vw;
    border-radius: 1.53vw;
  }
  .call-to-action-button__svg {
    height: 0.78vw;
    width: 1.48vw;
    right: 1.09vw;
  }
}
.footer {
  background-color: white;
}
.footer__link {
  color: black !important;
  text-decoration: none;
}
.footer__link:hover {
  color: black !important;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 799px) {
  .footer {
    padding: 45px 22px 22px 22px;
    margin-bottom: 140px;
  }
}
@media (min-width: 800px) {
  .footer {
    margin: 0vw 1.09vw 1.09vw 8.71vw;
    padding: 9.59vw 15.25vw 0.65vw 8.71vw;
    margin-bottom: 1.09vw;
  }
}
body[data-site=wiederInsLeben] .fui-i .fui-type-multi-line-text {
  display: none;
}
body[data-site=andreasFleischlin] .fui-i .fui-type-checkboxes[data-field-handle=angebote] {
  display: none;
}
.fui-i .fui-checkbox input:checked + label::before {
  background-image: url("/bausteine/formie/formie__checkbox.svg");
  background-size: 100%;
}
.fui-i .fui-checkbox input:checked + label::before, .fui-i .fui-radio input:checked + label::before {
  border-color: black;
  background-color: transparent;
}
body[data-site=wiederInsLeben] .fui-i .fui-submit {
  color: #2A8591;
}
body[data-site=andreasFleischlin] .fui-i .fui-submit {
  color: #B8C4B6;
}
body[data-site=bleibenOderGehen] .fui-i .fui-submit {
  color: #EF9362;
}
.fui-i .fui-submit:hover {
  color: black;
}
body[data-site=wiederInsLeben] .fui-i .fui-submit {
  background-image: url("/bausteine/formie/formie__pfeil-blau.svg");
  background-repeat: no-repeat;
  background-position: right center;
}
body[data-site=andreasFleischlin] .fui-i .fui-submit {
  background-image: url("/bausteine/formie/formie__pfeil-gruen.svg");
  background-repeat: no-repeat;
  background-position: right center;
}
body[data-site=bleibenOderGehen] .fui-i .fui-submit {
  background-image: url("/bausteine/formie/formie__pfeil-orange.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

@media (max-width: 799px) {
  .fui-i .fui-type-checkboxes[data-field-handle=newsletter] {
    margin-top: 52px;
  }
  .fui-i__bestaetigung {
    padding: 170px 22px 0px 22px;
  }
  .fui-i__bestaetigung p {
    font-size: 20px;
    line-height: 24px;
    margin: 24px 0px 24px 0px;
  }
  .fui-i .fui-submit {
    padding-right: 40px;
    background-size: 24.35px, auto;
  }
}
@media (min-width: 800px) {
  .fui-i {
    width: calc(((100% - 21.56vw) / 12 * 6 + 9.8vw) * 1);
  }
  .fui-i .fui-type-checkboxes[data-field-handle=newsletter] {
    margin-top: 2.27vw;
  }
  .fui-i .fui-submit {
    padding-right: 1.96vw;
    background-size: 1.31vw, auto;
  }
}
@media (max-width: 799px) {
  .header__logo {
    position: absolute;
    top: 15px;
    left: calc(((100% - 105px) / 8 * 0 + 22.5px) * 1);
    width: 56px;
    height: 105px;
  }
  .header__logo-svg {
    width: 100%;
    height: 100%;
  }
  .header__logo-svg {
    fill: white;
  }
}
@media (min-width: 800px) {
  .header__logo {
    display: none;
  }
}
@media (max-width: 799px) {
  .intro-abschnitte {
    display: flex;
    flex-direction: column;
  }
  .intro-abschnitte__titel {
    margin: 28px 0px 15px 0px;
    font-size: 34px;
    line-height: 34px;
    font-weight: 700;
  }
  .intro-abschnitte__untertitel {
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 800px) {
  .intro-abschnitte {
    margin-bottom: 6.58vw;
  }
  .body--intro .intro-abschnitte, body[data-site=wiederInsLeben] .intro-abschnitte {
    margin-bottom: 9.28vw;
  }
  .intro-abschnitte__titel {
    margin-bottom: 0.87vw;
    font-size: 4.36vw;
    line-height: 4.36vw;
    font-weight: 700;
  }
  .intro-abschnitte__untertitel {
    font-size: 2.27vw;
    line-height: 2.53vw;
  }
}
.intro__haupttext {
  z-index: 1;
}
.intro__titel, .intro__untertitel {
  color: #fff;
}
.intro__kreis, .intro__einstieg-bild {
  border-radius: 100%;
  overflow: hidden;
}
.intro__kreis--hauptbild {
  position: relative;
}
.intro__kreis--hover-bild.macro-bild {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  display: none;
}
.intro__kreis:hover .intro__kreis--hover-bild {
  display: block;
}
.intro__zitat {
  margin: 0;
}
.intro__zitat-quelle {
  color: #000;
}

@media (max-width: 799px) {
  .intro {
    display: flex;
    flex-wrap: wrap;
  }
  .intro__titel {
    margin-bottom: 10px;
    font-size: 34px;
    line-height: 34px;
    font-weight: 700;
  }
  .intro__untertitel {
    font-size: 20px;
    line-height: 24px;
  }
  .intro__bilder {
    margin: 0px -22px 0px -22px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
  }
  .intro__bild-startseite {
    width: 100%;
  }
  .intro__bild-startseite--desktop-bild {
    display: none !important;
  }
  .intro__haupttext {
    margin: 0px 22px 0px 22px;
    position: absolute;
    top: 25px;
    left: 0;
  }
  .intro__kreise {
    margin: 0px -7.5px 0px 14.5px;
    display: flex;
    position: absolute;
    bottom: -12vw;
    left: 0;
    width: 100%;
  }
  .intro__kreis--hauptbild {
    margin: 0px 7.5px 0px 7.5px;
    width: calc(((100% - 150px) / 8 * 4 + 45px) * 1);
  }
  .intro__kreis--hauptbild:nth-child(n+3) {
    display: none;
  }
  .intro__kreis--microsite {
    position: absolute;
    right: 22px;
    bottom: -12vw;
    width: calc(((100% - 150px) / 8 * 4 + 45px) * 1);
  }
  .intro__lead {
    margin: 69px 0px 40px 0px;
    font-size: 20px;
    line-height: 24px;
    width: 100%;
  }
  .intro__einstieg {
    margin-top: 84px;
  }
  .intro__einstieg-text {
    margin-bottom: 45px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }
  .intro__einstieg-autor {
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
  }
  .intro__einstieg-bild {
    width: calc(((100% - 135px) / 10 * 4 + 45px) * 1);
  }
  .intro__aktuell {
    display: flex;
    flex-direction: column;
  }
  .intro__smartphone-logo {
    padding: 7.5px 7.5px 7.5px 7.5px;
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .intro__smartphone-logo-svg {
    width: 55px;
    height: 81px;
    display: flex;
  }
}
@media (min-width: 800px) {
  body[data-site=wiederInsLeben] .intro {
    margin-bottom: 9.28vw;
  }
  .intro__haupttext {
    position: absolute;
    top: 3.36vw;
    left: 8.71vw;
  }
  .intro__titel {
    margin-bottom: 0.87vw;
    font-size: 4.36vw;
    line-height: 4.36vw;
    font-weight: 700;
  }
  .intro__untertitel {
    font-size: 2.27vw;
    line-height: 2.53vw;
  }
  .intro__bilder {
    margin-top: -2.75vw;
    position: relative;
    margin-left: -8.71vw;
  }
  .intro__bild-startseite {
    width: 100%;
  }
  .intro__bild-startseite--smartphone-bild {
    display: none !important;
  }
  .intro__kreise {
    margin: 0vw -0.87vw 0vw 7.84vw;
    display: flex;
    position: absolute;
    bottom: calc(0px - 3.05vw);
    left: 0;
    width: 100%;
  }
  .intro__kreis--hauptbild {
    margin: 0vw 0.87vw 0vw 0.87vw;
    width: calc(((100% - 21.56vw) / 12 * 2 + 3.92vw) * 1);
  }
  .intro__kreis--microsite {
    position: absolute;
    right: calc(((100% - 29.4vw) / 12 * 1 + 1.96vw) * 1);
    bottom: -4.36vw;
    width: calc(((100% - 21.56vw) / 12 * 4 + 5.88vw) * 1);
  }
  .intro__lead {
    margin: 6.01vw 0vw 6.01vw 0vw;
    width: 100%;
  }
  .intro__einstieg {
    margin: 6.01vw 0vw 3.83vw 0vw;
    width: calc(((100% - 21.56vw) / 12 * 10 + 17.64vw) * 1);
    display: block;
  }
  .intro__einstieg-text {
    margin-bottom: 4.1vw;
    font-size: 2.27vw;
    line-height: 2.53vw;
    font-weight: 400;
  }
  .intro__einstieg-autor {
    margin-bottom: 2.27vw;
    font-size: 1.53vw;
    line-height: 1.53vw;
    font-weight: 700;
  }
  .intro__einstieg-bild {
    width: calc(((100% - 17.64vw) / 10 * 2 + 3.92vw) * 1);
  }
  .intro__aktuell {
    display: flex;
    flex-wrap: wrap;
  }
  .intro__aktuell-intro {
    margin: 10.68vw 0vw 2.83vw 0vw;
    font-size: 2.27vw;
    line-height: 2.27vw;
    font-weight: 700;
    color: #C1D3C1;
    width: 100%;
  }
  .intro__aktuell-spaltenabstand {
    width: 3.49vw;
  }
  .intro__aktuell-strich {
    width: 4.66vw;
    height: 2.33vw;
  }
  .intro__aktuell-container {
    width: calc(((100% - 21.56vw) / 12 * 3.35 + 6.566vw) * 1);
  }
  .intro__aktuell-datum {
    font-size: 1.05vw;
    line-height: 1.66vw;
    font-weight: 700;
  }
  .intro__aktuell-titel {
    margin-bottom: 0.22vw;
    font-size: 1.57vw;
    line-height: 1.66vw;
    font-weight: 700;
  }
  .intro__aktuell-text {
    margin-bottom: 1.09vw;
    font-size: 0.87vw;
    line-height: 1.13vw;
    font-weight: 300;
  }
  .intro__smartphone-logo {
    display: none;
  }
  .intro__lead {
    margin-top: 6.01vw;
    font-size: 2.27vw;
    line-height: 2.53vw;
    font-weight: 300;
  }
  .intro__text {
    margin: 2.18vw 0vw 2.18vw 0vw;
    font-size: 1.05vw;
    line-height: 1.22vw;
    font-weight: 300;
  }
  .intro__zitat {
    margin: 3.83vw 0vw 3.83vw 0vw;
    font-size: 1.48vw;
    line-height: 1.57vw;
    font-weight: 700;
  }
  .intro__zitat-quelle {
    font-size: 0.87vw;
    line-height: 1.13vw;
    font-weight: 300;
    margin-top: 0.65vw;
  }
  .intro .call-to-action-button {
    margin-top: -3.4vw;
  }
}
@media (max-width: 799px) {
  .karte__container {
    margin: 45px 0px 45px 0px;
    width: 100%;
  }
  .karte__karte {
    height: 440px;
  }
}
@media (min-width: 800px) {
  .karte__container {
    margin: 7.19vw 0vw 0vw 0vw;
    width: 100%;
  }
  .karte__karte {
    height: 27.63vw;
  }
}
.kontaktformular__label {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.kontaktformular__input {
  width: 100%;
  border-radius: 0;
  border: 1px solid black;
  font-family: "Source Serif Pro", serif;
}
.kontaktformular__checkbox-label {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.kontaktformular__checkbox {
  border-radius: 0;
  border: 1px solid black;
  cursor: pointer;
}
.kontaktformular__label {
  cursor: pointer;
}
.kontaktformular__bestaetigung {
  background-color: white;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.kontaktformular__bestaetigung[data-bestaetigt=false] {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear;
}
.kontaktformular__bestaetigung[data-bestaetigt=true] {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.2s linear;
}
.kontaktformular__bestaetigung-schliessen {
  border: none;
  background-color: white;
  cursor: pointer;
  position: absolute;
}
body[data-site=wiederInsLeben] .kontaktformular .fui-type-multi-line-text {
  display: none;
}
body[data-site=andreasFleischlin] .kontaktformular .fui-type-checkboxes[data-field-handle=angebote] {
  display: none;
}
.kontaktformular__recaptcha a {
  color: black;
}

@media (max-width: 799px) {
  .kontaktformular {
    margin-bottom: 90px;
  }
  .kontaktformular__label {
    font-size: 20px;
    line-height: 28px;
  }
  .kontaktformular__abschnitt {
    margin: 0px 0px 20px 0px;
    font-size: 20px;
    line-height: 28px;
  }
  .kontaktformular__angebote {
    padding: 0px 0px 45px 0px;
    display: flex;
    flex-direction: column;
  }
  .kontaktformular__checkbox {
    margin: 0px 10px 0px 0px;
    height: 30px;
    width: 30px;
  }
  .kontaktformular__checkbox-label {
    font-size: 20px;
    line-height: 28px;
    padding-top: 10px;
  }
  .kontaktformular__kontaktfelder {
    margin-bottom: 45px;
  }
  .kontaktformular__feld {
    margin: 10px 0px 10px 0px;
  }
  .kontaktformular__input {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    padding: 0px 10px 0px 10px;
    height: 42px;
  }
  .kontaktformular__bestaetigung {
    padding: 170px 22px 0px 22px;
  }
  .kontaktformular__bestaetigung p {
    font-size: 20px;
    line-height: 24px;
    margin: 24px 0px 24px 0px;
  }
  .kontaktformular__bestaetigung-schliessen {
    right: 22px;
    top: 26px;
  }
  .kontaktformular__bestaetigung-schliessen-svg {
    height: 30px;
    width: 30px;
  }
  .kontaktformular__recaptcha {
    font-size: 20px;
    margin-top: 45px;
  }
}
@media (min-width: 800px) {
  .kontaktformular__label {
    font-size: 0.87vw;
    line-height: 1.22vw;
  }
  .kontaktformular__abschnitt {
    margin: 0vw 0vw 0.87vw 0vw;
    font-size: 0.87vw;
    line-height: 1.22vw;
  }
  .kontaktformular__angebote {
    padding: 0vw 0vw 2.27vw 0vw;
    columns: 2;
    column-gap: 1.96vw;
  }
  .kontaktformular__checkbox {
    margin: 0vw 0.78vw 0vw 0vw;
    height: 1.83vw;
    width: 1.83vw;
  }
  .kontaktformular__checkbox-label {
    font-size: 0.87vw;
    line-height: 1.05vw;
    padding-top: 0.44vw;
  }
  .kontaktformular__kontaktfelder {
    margin-bottom: 2.27vw;
  }
  .kontaktformular__feld {
    margin: 0.87vw 0vw 0.87vw 0vw;
  }
  .kontaktformular__input {
    font-size: 1.05vw;
    line-height: 1.22vw;
    padding: 0vw 0.44vw 0.13vw 0.44vw;
    height: 1.83vw;
  }
  .kontaktformular__bestaetigung {
    padding: 17.43vw 17.43vw 0vw 17.43vw;
  }
  .kontaktformular__bestaetigung p {
    font-size: 1.05vw;
    line-height: 1.22vw;
    margin: 1.22vw 0vw 1.22vw 0vw;
  }
  .kontaktformular__bestaetigung-schliessen {
    top: 3.92vw;
  }
  .kontaktformular__bestaetigung-schliessen-svg {
    height: 2vw;
    width: 2vw;
  }
  .kontaktformular__recaptcha {
    font-size: 0.87vw;
    margin-top: 3.83vw;
    width: calc(((100% - 21.56vw) / 12 * 6 + 9.8vw) * 1);
  }
}
.logo__svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 799px) {
  .logo {
    display: flex;
    justify-content: flex-end;
    margin: 11px 0px 22px 0px;
  }
  .logo__svg--smartphone {
    height: 81px;
    width: 56px;
  }
  .logo__svg--desktop {
    display: none;
  }
}
@media (min-width: 800px) {
  .logo {
    padding-bottom: 2.27vw;
    margin-right: 2.27vw;
    bottom: 0;
    position: fixed;
    right: 0;
    width: 7.23vw;
  }
  .logo__svg--smartphone {
    display: none;
  }
}
.menue {
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 100;
}
.menue__shortlink {
  display: none;
}
.menue__link {
  align-items: flex-start;
  display: block;
  transform-origin: top left;
  transform: rotate(-19deg);
  text-decoration: none;
}
body[data-site=wiederInsLeben] .menue__link {
  color: #7EB5BD;
}
body[data-site=bleibenOderGehen] .menue__link {
  color: #EF9362;
}
body[data-site=andreasFleischlin] .menue__link {
  color: black;
}
body[data-site=wiederInsLeben] .menue__link:hover {
  color: black;
}

@media (max-width: 799px) {
  .menue {
    padding: 150px 22px 22px 22px;
    font-size: 26px;
    line-height: 38px;
    font-weight: 700;
    background-color: white;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    visibility: hidden;
    width: 100%;
  }
  body[data-menue-aktiv=true] .menue {
    display: flex;
    justify-content: space-between;
  }
  body[data-menue-aktiv=true] .menue {
    visibility: visible;
  }
  .menue__link--1 {
    margin-top: 30px;
  }
  .menue__hamburger {
    padding: 17px 23px 17px 23px;
    position: fixed;
    right: 0;
    top: 0;
    height: 85px;
    width: 99px;
    z-index: 101;
    cursor: pointer;
  }
  .menue__kurzzugriffe-smartphone {
    margin: 0px -10px 0px -10px;
    display: none;
    left: calc(((100% - 105px) / 8 * 0 + 22.5px) * 1);
    position: absolute;
    top: 17px;
    z-index: 110;
  }
  body[data-menue-aktiv=true] .menue__kurzzugriffe-smartphone {
    display: flex;
  }
  .menue__kurzzugriff-smartphone {
    margin: -5px 5px 0px 5px;
    padding: 5px 5px 5px 5px;
  }
  .menue__kurzzugriff-smartphone--at {
    width: 43.5px;
    height: 43.9px;
  }
  .menue__kurzzugriff-smartphone--pin {
    width: 38.5px;
    height: 51px;
  }
  .menue__kurzzugriff-smartphone--instagram {
    width: 44.75px;
    height: 44.8px;
  }
  .menue__kurzzugriff-smartphone--linkedin {
    width: 44.7px;
    height: 44.7px;
  }
  .menue__kurzzugriff-smartphone-svg {
    width: 100%;
    height: 100%;
  }
  .menue__logo {
    align-self: flex-end;
    height: 203px;
    margin-top: auto;
    width: 109px;
  }
  .menue__logo-svg {
    height: 100%;
    width: 100%;
  }
  .menue__kurzzugriffe-desktop {
    display: none;
  }
}
@media (min-width: 800px) {
  .menue {
    padding: 1.09vw 0vw 0vw 0vw;
    font-size: 1.22vw;
    line-height: 1.61vw;
    font-weight: 700;
    right: 0;
    width: 18.52vw;
    height: 100%;
  }
  .body[data-site=andreasFleischlin] .menue__links {
    margin-top: 3.27vw;
    order: 2;
  }
  .body[data-site=wiederInsLeben] .menue__links, .body[data-site=bleibenOderGehen] .menue__links {
    margin-top: 6.41vw;
  }
  .menue__link--1 {
    margin-top: 1.31vw;
  }
  .menue__hamburger {
    display: none;
  }
  .menue__logo {
    margin-top: 3.36vw;
    height: 16.34vw;
    width: 8.76vw;
  }
  .body[data-site=andreasFleischlin] .menue__logo {
    order: 1;
  }
  .body[data-site=wiederInsLeben] .menue__logo, .body[data-site=bleibenOderGehen] .menue__logo {
    height: 7.23vw;
    margin-top: auto;
    width: 4.27vw;
  }
  .menue__logo-svg {
    width: 100%;
    height: 100%;
  }
  .menue__kurzzugriffe-smartphone {
    display: none;
  }
  .menue__kurzzugriffe-desktop {
    margin-left: -0.44vw;
    padding: 3.7vw 0vw 3.27vw 0vw;
    align-items: flex-end;
    display: flex;
    margin-right: 1.09vw;
  }
  .body[data-site=andreasFleischlin] .menue__kurzzugriffe-desktop {
    flex: 1;
    order: 3;
  }
  .menue__kurzzugriffe-desktop-container {
    display: flex;
  }
  .menue__kurzzugriff-desktop-svg {
    margin: 0vw 0.44vw 0vw 0.44vw;
    height: 1.48vw;
    width: 1.48vw;
  }
}
@media (max-width: 799px) {
  .rechteckige-bilder {
    margin: 45px 0px 45px 0px;
  }
  .rechteckige-bilder__bild-container {
    margin: 45px 0px 45px 0px;
  }
  .rechteckige-bilder__bildlegende {
    margin-top: 5px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
  }
}
@media (min-width: 800px) {
  .rechteckige-bilder {
    width: calc(((100% - 21.56vw) / 12 * 6 + 9.8vw) * 1);
  }
  .rechteckige-bilder__bild-container {
    margin-bottom: 2.18vw;
  }
  .rechteckige-bilder__bild-container--schmal {
    width: 50%;
  }
  .rechteckige-bilder__bildlegende {
    margin-top: 0.65vw;
    font-size: 1.05vw;
    line-height: 1.22vw;
    font-weight: 400;
  }
}
.rueckmeldungen__bild-img {
  border-radius: 100%;
  overflow: hidden;
  width: 100%;
}
.rueckmeldungen__rueckmeldung {
  break-inside: avoid;
  page-break-inside: avoid;
}
body[data-site=wiederInsLeben] .rueckmeldungen__schreiben {
  color: #2A8591;
}
body[data-site=andreasFleischlin] .rueckmeldungen__schreiben:hover {
  color: black;
  fill: black;
}
body[data-site=andreasFleischlin] .rueckmeldungen__schreiben:hover .button__pfeil {
  color: black;
  fill: black;
}

@media (max-width: 799px) {
  .rueckmeldungen__bild {
    margin: 45px 0px 45px 0px;
  }
  .rueckmeldungen__rueckmeldungen {
    margin-bottom: 45px;
  }
  .rueckmeldungen__rueckmeldung {
    margin: 0px 0px 45px 0px;
    font-size: 20px;
    line-height: 24px;
  }
}
@media (min-width: 800px) {
  .rueckmeldungen {
    margin: 2.27vw -0.98vw 2.27vw -0.98vw;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1.96vw);
  }
  .rueckmeldungen__rueckmeldungen, .rueckmeldungen__bild {
    padding: 0vw 0.98vw 0vw 0.98vw;
  }
  .rueckmeldungen__bild {
    width: 33.3333333333%;
  }
  .rueckmeldungen__rueckmeldungen {
    margin-bottom: 2.27vw;
    width: 66.6666666667%;
    columns: 2;
    column-gap: 1.96vw;
  }
  .rueckmeldungen__rueckmeldung {
    margin: 0vw 0vw 2.27vw 0vw;
    font-size: 0.87vw;
    line-height: 1.13vw;
  }
  .rueckmeldungen__schreiben {
    padding-left: 0.98vw;
  }
}
.scrollpfeil__knopf {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.scrollpfeil__svg {
  height: 100%;
  width: 100%;
}

@media (max-width: 799px) {
  .scrollpfeil {
    margin: 45px 0px 0px 0px;
    background: none;
    bottom: 0;
    border: none;
    cursor: pointer;
    display: block;
    height: 55px;
    left: 0;
    padding: 0;
    width: 27px;
  }
  .scrollpfeil--desktop {
    display: none;
  }
}
@media (min-width: 800px) {
  .scrollpfeil {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 2.35vw;
  }
  .scrollpfeil--smartphone {
    display: none;
  }
  .scrollpfeil__abstand {
    height: 100vh;
  }
  .scrollpfeil__knopf {
    margin: 0vw 0vw 1.83vw -6.45vw;
    padding: 0.44vw 0.44vw 0.44vw 0.44vw;
    height: 4.36vw;
    position: sticky;
    top: calc(100vh - 4.36vw);
    width: 2.35vw;
  }
}
.shop__produkt-container {
  background-color: #f4f7f4;
}
.shop__titel {
  font-weight: 700;
}
.shop__kategorie {
  color: #C1D3C1;
  font-weight: 700;
}
.shop__anzahl {
  display: flex;
  align-items: center;
}
.shop__anzahl-knopf {
  align-items: center;
  background-color: white;
  border: 1px solid black;
  display: grid;
  justify-content: center;
  padding: 0;
}
.shop__anzahl-knopf:hover {
  background-color: #C1D3C1;
}
.shop__anzahl-knopf:hover::after, .shop__anzahl-knopf:hover::before {
  background-color: white;
  cursor: pointer;
}
.shop__anzahl-knopf::after, .shop__anzahl-knopf::before {
  background-color: black;
  content: "";
  grid-column: 1/span 1;
  grid-row: 1/span 1;
}
.shop__anzahl-knopf--plus::after {
  transform: rotate(90deg);
}
.shop__anzahl-anzahl {
  background-color: transparent;
  border: none;
  font-family: "Source Serif Pro", serif;
  font-weight: 700;
  text-align: center;
}
.shop .fui-field[data-field-handle=bestellung] .fui-input-container {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.shop .fui-field[data-field-handle=bestellung] .fui-input-container::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.shop .fui-field[data-field-handle=bestellung] textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;
  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.shop .fui-field[data-field-handle=bestellung] textarea, .shop .fui-field[data-field-handle=bestellung] .fui-input-container::after {
  /* Identical styling required!! */
  border: none;
  color: black;
  padding: 0;
  font-weight: 700;
  /* Place on top of each other */
  grid-area: 1/1/2/2;
}
.shop__recaptcha a {
  color: black;
}

@media (max-width: 799px) {
  .shop__produkte {
    margin-bottom: 145px;
    margin: 0px 0px 145px -7.5px;
  }
  .shop__produkt-breite {
    width: 50%;
  }
  .shop__produkt {
    padding: 0px 7px 14px 7px;
    font-size: 16px;
    line-height: 18px;
    width: 50%;
  }
  .shop__produkt-container {
    padding: 0px 7.5px 7.5px 7.5px;
  }
  .shop__bild {
    margin: 0px -7px 7px -7px;
  }
  .shop__titel {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 18px;
  }
  .shop__beschreibung p {
    margin-bottom: 12px;
  }
  .shop__anzahl {
    margin-top: 18px;
  }
  .shop__anzahl-knopf {
    height: 22px;
    width: 22px;
  }
  .shop__anzahl-knopf::after, .shop__anzahl-knopf::before {
    height: 2px;
    width: 13px;
  }
  .shop__anzahl-anzahl {
    font-size: 16px;
    line-height: 18px;
    max-width: 54px;
    min-width: 18px;
  }
  .shop__anzahl-wort {
    padding-left: 16px;
  }
  .shop .fui-field[data-field-handle=bestellung] textarea, .shop .fui-field[data-field-handle=bestellung] .fui-input-container::after {
    font-size: 20px;
    line-height: 24px;
  }
  .shop__recaptcha {
    font-size: 20px;
    margin-top: 45px;
  }
}
@media (min-width: 800px) {
  .shop__produkte {
    margin: 0vw 0vw 7.41vw -0.98vw;
    width: calc(((100% - 21.56vw) / 12 * 9 + 17.64vw) * 1);
  }
  .shop__produkt-breite {
    width: 33.3%;
  }
  .shop__produkt {
    padding: 0vw 0.98vw 1.96vw 0.98vw;
    font-size: 1.05vw;
    line-height: 1.22vw;
    width: 33.33%;
  }
  .shop__produkt-container {
    padding: 0vw 0.65vw 0.65vw 0.65vw;
  }
  .shop__bild {
    margin: 0vw -0.65vw 0.65vw -0.65vw;
  }
  .shop__titel {
    margin-bottom: 0.7vw;
    font-size: 1.05vw;
    line-height: 1.22vw;
  }
  .shop__beschreibung p {
    margin-bottom: 0.7vw;
  }
  .shop__anzahl {
    margin-top: 1.48vw;
  }
  .shop__anzahl-knopf {
    height: 1.74vw;
    width: 1.74vw;
  }
  .shop__anzahl-knopf::after, .shop__anzahl-knopf::before {
    height: 0.13vw;
    width: 1vw;
  }
  .shop__anzahl-anzahl {
    font-size: 1.05vw;
    line-height: 1.22vw;
    width: 4.01vw;
  }
  .shop__anzahl-wort {
    padding-left: 0.7vw;
  }
  .shop .fui-field[data-field-handle=bestellung] textarea, .shop .fui-field[data-field-handle=bestellung] .fui-input-container::after {
    font-size: 1.05vw;
    line-height: 1.22vw;
  }
  .shop__recaptcha {
    font-size: 0.87vw;
    margin-top: 3.83vw;
    width: calc(((100% - 21.56vw) / 12 * 6 + 9.8vw) * 1);
  }
}
.signatur p:last-child {
  margin-bottom: 0;
}
.signatur__text a {
  color: black;
  text-decoration: none;
}
.signatur__instagram {
  font-weight: 700;
  text-decoration: none;
}
.signatur__instagram:hover {
  color: black;
  fill: black;
}

@media (max-width: 799px) {
  .signatur {
    margin: 145px 0px 45px 0px;
    font-size: 20px;
    line-height: 24px;
  }
  .signatur p {
    margin: 24px 0px 24px 0px;
  }
  .signatur__instagram--svg {
    margin-top: 30px;
    height: 38px;
    width: 35px;
  }
}
@media (min-width: 800px) {
  .signatur {
    margin: 7.19vw 0vw 11.55vw 0vw;
    font-size: 1.05vw;
    line-height: 1.22vw;
    margin-top: 11.55vw;
  }
  .signatur p {
    margin: 1.22vw 0vw 1.22vw 0vw;
  }
  .signatur__instagram--svg {
    margin-top: 1.31vw;
    height: 2.18vw;
    width: 2.18vw;
  }
}
.text {
  hyphens: auto;
}
.text__bild-img {
  border-radius: 100%;
  overflow: hidden;
  width: 100%;
}
.text a {
  color: var(--abschnittsfarbe);
  text-decoration: none;
  font-weight: 700;
}
.text a:hover {
  color: black;
}
.text p:first-child, .text h4:first-child {
  margin-top: 0;
}
.text p:last-child, .text h4:last-child {
  margin-bottom: 0;
}

@media (max-width: 799px) {
  .text {
    margin: 45px 0px 45px 0px;
    width: 100%;
  }
  .text__text {
    margin: 45px 0px 18px 0px;
    font-size: 20px;
    line-height: 24px;
  }
  .text__text ul {
    margin: 24px 0px 24px 0px;
  }
  .text__text li {
    padding-left: 25px;
  }
  .text__text li::before {
    top: -1px;
  }
  .text__text p {
    margin-bottom: 27px;
  }
  .text__text h4 {
    margin-bottom: 27px;
  }
  .text__link {
    margin: 22px 0px 22px 0px;
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
  }
  .text h4 {
    margin-top: 22px;
  }
}
@media (min-width: 800px) {
  .text {
    margin: 2.27vw -0.98vw 2.27vw -0.98vw;
    align-items: flex-start;
    display: flex;
    width: calc(100% + 1.96vw);
  }
  .text__text, .text__bild {
    padding: 0vw 0.98vw 0vw 0.98vw;
  }
  .text__text {
    font-size: 1.05vw;
    line-height: 1.22vw;
    width: 66.6666666667%;
  }
  .text__text ul {
    margin: 1.22vw 0vw 0.04vw 0vw;
  }
  .text__text li {
    padding-left: 1.09vw;
  }
  .text__text li::before {
    top: -0.04vw;
  }
  .text__text p {
    margin-bottom: 1.18vw;
  }
  .text__text h4 {
    margin-bottom: 1.18vw;
  }
  .text__bild {
    width: 33.3333333333%;
  }
  .text__link {
    margin: 1.39vw 0vw 1.39vw 0vw;
    font-size: 2.27vw;
    line-height: 2.53vw;
    font-weight: 700;
  }
  .text h4 {
    margin-top: 1.09vw;
  }
}
.zitat {
  margin: 0;
}
[data-site=wiederInsLeben] .zitat {
  color: #BFDBDE;
}
[data-site=bleibenOderGehen] .zitat {
  color: #EF9362;
}
.zitat__quelle {
  color: #000;
}

@media (max-width: 799px) {
  .zitat {
    margin: 40px 0px 40px 0px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 700;
  }
  .zitat__quelle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 10px;
  }
}
@media (min-width: 800px) {
  .zitat {
    margin: 3.83vw 0vw 3.83vw 0vw;
    font-size: 1.48vw;
    line-height: 1.57vw;
    font-weight: 700;
    width: calc(((100% - 21.56vw) / 12 * 6 + 9.8vw) * 1);
  }
  [data-site=wiederInsLeben] .zitat {
    color: #BFDBDE;
  }
  [data-site=bleibenOderGehen] .zitat {
    color: #EF9362;
  }
  .zitat__quelle {
    font-size: 0.87vw;
    line-height: 1.13vw;
    font-weight: 300;
    margin-top: 0.65vw;
  }
}
.zwischentitel {
  width: 100%;
}
body[data-site=wiederInsLeben] .zwischentitel {
  color: #7EB5BD;
}
body[data-site=bleibenOderGehen] .zwischentitel {
  color: #EF9362;
}

@media (max-width: 799px) {
  .zwischentitel {
    margin: 45px 0px 45px 0px;
    font-size: 35px;
    line-height: 40px;
    font-weight: 600;
  }
}
@media (min-width: 800px) {
  .zwischentitel {
    margin: 2.27vw 0vw 2.27vw 0vw;
    font-size: 2.27vw;
    line-height: 2.53vw;
    font-weight: 600;
  }
  body[data-site=andreasFleischlin] .zwischentitel {
    margin: 7.19vw 0vw 2.27vw 0vw;
  }
}
.macro-bild {
  position: relative;
  display: block;
  height: 0;
  width: 100%;
}
.macro-bild .lazyload,
.macro-bild .lazyloading {
  opacity: 0;
}
.macro-bild .lazyloaded {
  transition: background-color 500ms ease-in;
  opacity: 1;
  background-color: transparent;
  /*  transition is applied to lazyloaded not lazyload */
  transition: opacity 500ms ease-in;
}

.macro-bild__img,
.macro-bild__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

body[data-menue-aktiv=true] {
  position: fixed;
  width: 100%;
}
body[data-menue-aktiv=true] .main {
  pointer-events: none;
}

.seite {
  position: relative;
}

@media (max-width: 799px) {
  .seite {
    width: 100%;
    height: 100%;
  }
}
.main {
  position: relative;
  background-color: white;
}

@media (max-width: 799px) {
  .main {
    padding: 0px 22px 0px 22px;
    margin: 140px 0px 0px 0px;
  }
  body[data-site=andreasFleischlin] .main {
    min-height: calc(100vh - 140px);
  }
}
@media (min-width: 800px) {
  .main {
    padding: 2.75vw 20.7vw 9.59vw 8.71vw;
    margin: 1.09vw 1.09vw 0vw 8.71vw;
  }
  body[data-site=andreasFleischlin] .main {
    min-height: calc(100vh - 1.09vw);
  }
}
.fui-i {
  --fui-font: "Source Serif Pro", sans-serif;
  --fui-font-size: 16px;
  --fui-color: #374151;
  --fui-primary-color: #2A8591;
  --fui-primary-color-hover: #2A8591;
  --fui-error: #f56565;
  --fui-success: #00ae2a;
  --fui-gray-100: #F3F4F6;
  --fui-gray-200: #E5E7EB;
  --fui-gray-300: #D1D5DB;
  --fui-gray-400: #9CA3AF;
  --fui-gray-500: #6B7280;
  --fui-gray-600: #4B5563;
  --fui-gray-700: #374151;
  --fui-gray-800: #1F2937;
  --fui-gray-900: #111827;
  --fui-border: 0 solid #D1D5DB;
  --fui-border-radius: 0;
  --fui-border-color: #D1D5DB;
  --fui-focus-border-color: #a4cafe;
  --fui-focus-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
  --fui-row-gutter: 1.25rem;
  --fui-row-gutter-top: 1.5rem;
  --fui-field-gutter: 0.5rem;
  --fui-loading-min-height: 1rem;
  --fui-loading-height: 1rem;
  --fui-loading-width: 1rem;
  --fui-loading-margin-top: -0.5rem;
  --fui-loading-margin-left: -0.5rem;
  --fui-loading-border-width: 2px;
  --fui-loading-animation: loading 0.5s infinite linear;
  --fui-loading-left: 50%;
  --fui-loading-top: calc(50% - 1px);
  --fui-loading-z-index: 1;
  --fui-alert-padding: 1rem;
  --fui-alert-line-height: 1.25rem;
  --fui-alert-font-size: 0.875rem;
  --fui-alert-font-weight: 500;
  --fui-alert-margin-bottom: 1rem;
  --fui-alert-error-bg-color: #fdf2f2;
  --fui-alert-error-color: #771d1d;
  --fui-alert-success-bg-color: #f3faf7;
  --fui-alert-success-color: #03543f;
  --fui-btn-display: inline-block;
  --fui-btn-text-align: center;
  --fui-btn-white-space: nowrap;
  --fui-btn-vertical-align: middle;
  --fui-btn-border-style: none;
  --fui-btn-text-decoration: none;
  --fui-btn-padding: 0px;
  --fui-btn-border: 0px solid transparent;
  --fui-btn-font-weight: 700;
  --fui-btn-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  --fui-btn-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --fui-btn-transition-duration: 0.15s;
  --fui-btn-opacity-disabled: 0.65;
  --fui-btn-container-padding: 1rem 0 0 0;
  --fui-btn-container-margin: 0 -0.5rem 0 -0.5rem;
  --fui-btn-margin: 0 0.5rem 0 0.5rem;
  --fui-link-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  --fui-link-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --fui-link-transition-duration: 0.15s;
  --fui-link-text-decoration: underline;
  --fui-submit-btn-color: #2A8591;
  --fui-submit-btn-color-hover: #2A8591;
  --fui-submit-btn-spinner-color: #2A8591;
  --fui-title-margin: 0 0 1rem;
  --fui-title-font-size: 1.4rem;
  --fui-title-font-weight: 700;
  --fui-page-title-margin: 0 0 1rem;
  --fui-page-title-font-size: 1.125rem;
  --fui-page-title-font-weight: 600;
  --fui-tabs-margin-bottom: 1rem;
  --fui-tabs-font-size: 0.875rem;
  --fui-tab-padding: 0.5rem 1rem;
  --fui-tab-margin-bottom: -1px;
  --fui-tab-border: 1px solid transparent;
  --fui-tab-active-font-weight: 500;
  --fui-tab-active-bg-color: #fff;
  --fui-tab-active-border-bottom-color: transparent;
  --fui-progress-height: 1.2rem;
  --fui-progress-font-size: 0.8rem;
  --fui-progress-font-weight: 500;
  --fui-progress-color: #fff;
  --fui-progress-bar-transition: width 0.3s ease;
  --fui-error-font-size: 0.875rem;
  --fui-error-margin-top: 0.5rem;
  --fui-label-font-weight: 300;
  --fui-label-error-color: #771d1d;
  --fui-label-error-border-color: #f8b4b4;
  --fui-instructions-font-size: 0.875rem;
  --fui-instructions-line-height: 1.25;
  --fui-instructions-margin: 0.5rem;
  --fui-check-margin-right: 1rem;
  --fui-check-bg-color: #f0f1f4;
  --fui-check-label-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  --fui-check-label-top: 0;
  --fui-check-label-bg-color: #fff;
  --fui-check-label-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  --fui-check-check-border-radius: 0;
  --fui-check-check-bg-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  --fui-check-check-bg-size: 8px auto;
  --fui-check-radio-border-radius: 50%;
  --fui-check-radio-bg-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  --fui-check-radio-bg-size: 8px auto;
  --fui-group-padding: 1rem;
  --fui-input-background-color: #fff;
  --fui-input-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  --fui-input-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --fui-input-transition-duration: 0.15s;
  --fui-input-error-color: #771d1d;
  --fui-input-error-border-color: #f8b4b4;
  --fui-input-error-box-shadow-focus: 0 0 0 3px rgba(248, 180, 180, 0.45);
  --fui-repeater-row-padding: 1rem;
  --fui-repeater-row-margin-bottom: 1rem;
  --fui-repeater-add-btn-padding-left: 2rem;
  --fui-repeater-add-btn-top: 0.75rem;
  --fui-repeater-add-btn-left: 0.75rem;
  --fui-repeater-add-btn-width: 14px;
  --fui-repeater-add-btn-height: 14px;
  --fui-repeater-add-btn-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'%3E%3C/path%3E%3C/svg%3E");
  --fui-repeater-remove-btn-top: 0;
  --fui-repeater-remove-btn-right: -14px;
  --fui-repeater-remove-btn-transform: translate(0, -50%);
  --fui-repeater-remove-btn-border-radius: 50%;
  --fui-repeater-remove-btn-height: 0;
  --fui-repeater-remove-btn-width: 0;
  --fui-repeater-remove-btn-padding: 13px;
  --fui-repeater-remove-btn-text-indent: -9999px;
  --fui-repeater-remove-btn-icon-top: 50%;
  --fui-repeater-remove-btn-icon-left: 50%;
  --fui-repeater-remove-btn-icon-width: 9px;
  --fui-repeater-remove-btn-icon-height: 14px;
  --fui-repeater-remove-btn-icon-transform: translate(-50%, -50%);
  --fui-repeater-remove-btn-icon-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  --fui-select-option-padding: 0.1rem 0.4rem;
  --fui-select-padding-right: 2rem;
  --fui-select-bg-position: right 0.25rem center;
  --fui-select-bg-size: 1.5em 1.5em;
  --fui-select-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  --fui-table-width: 100%;
  --fui-table-margin-bottom: 1rem;
  --fui-table-border-collapse: collapse;
  --fui-table-row-padding: 0.2rem;
  --fui-table-th-text-align: inherit;
  --fui-table-th-font-size: 0.75rem;
  --fui-table-th-font-weight: 600;
  --fui-table-add-btn-padding-left: 2rem;
  --fui-table-add-btn-top: 0.75rem;
  --fui-table-add-btn-left: 0.75rem;
  --fui-table-add-btn-width: 14px;
  --fui-table-add-btn-height: 14px;
  --fui-table-add-btn-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath fill='currentColor' d='M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z'%3E%3C/path%3E%3C/svg%3E");
  --fui-table-remove-btn-border-radius: 50%;
  --fui-table-remove-btn-padding: 13px;
  --fui-table-remove-btn-text-indent: -9999px;
  --fui-table-remove-btn-top: 50%;
  --fui-table-remove-btn-left: 50%;
  --fui-table-remove-btn-width: 9px;
  --fui-table-remove-btn-height: 14px;
  --fui-table-remove-btn-transform: translate(-50%, -50%);
  --fui-table-remove-btn-bg-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  --fui-signature-width: 100%;
  --fui-signature-height: 8rem;
  --fui-signature-bg: #f9fafb;
  --fui-signature-remove-btn-top: 0;
  --fui-signature-remove-btn-right: -14px;
  --fui-signature-remove-btn-transform: translate(0, -50%);
  --fui-signature-remove-btn-border-radius: 50%;
  --fui-signature-remove-btn-height: 0;
  --fui-signature-remove-btn-width: 0;
  --fui-signature-remove-btn-padding: 13px;
  --fui-signature-remove-btn-text-indent: -9999px;
  --fui-signature-remove-btn-icon-top: 50%;
  --fui-signature-remove-btn-icon-left: 50%;
  --fui-signature-remove-btn-icon-width: 9px;
  --fui-signature-remove-btn-icon-height: 14px;
  --fui-signature-remove-btn-icon-transform: translate(-50%, -50%);
  --fui-signature-remove-btn-icon-bg-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' class='svg-inline--fa fa-times fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='currentColor' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'%3E%3C/path%3E%3C/svg%3E");
  --fui-summary-padding: 1rem;
}
.fui-i input, .fui-i .fui-checkbox label::before, .fui-i .fui-radio label::before, .fui-i textarea {
  font-weight: 700;
  border: 1px solid black;
}
.fui-i .fui-label {
  color: black;
}
.fui-i .iti__selected-dial-code {
  font-weight: 700;
}
.fui-i .fui-btn {
  background-color: transparent;
}
.fui-i .fui-btn:hover {
  background-color: transparent;
}
.fui-i .fui-row {
  display: block;
}

.grecaptcha-badge {
  display: none;
}

@media (max-width: 799px) {
  .fui-i {
    --fui-label-font-size: 20px;
    --fui-label-line-height: 28px;
    --fui-label-margin: 0px;
    --fui-input-font-size: 20px;
    --fui-input-line-height: 24px;
    --fui-input-width: 100%;
    --fui-input-padding: 8px;
    --fui-btn-font-size: 28px;
    --fui-btn-line-height: 28px;
    --fui-check-margin-bottom: 12px;
    --fui-check-font-size: 20px;
    --fui-check-line-height: 20px;
    --fui-check-label-width: 40px;
    --fui-check-label-height: 40px;
    --fui-check-label-padding-left: 55px;
    --fui-check-label-line-height: 28px;
  }
  .fui-i .iti__selected-dial-code {
    line-height: 24px;
    font-size: 24px;
  }
  .fui-i .fui-type-checkboxes[data-field-handle=angebote] .fui-input-container {
    margin: 30px 0px 30px 0px;
  }
  .fui-i .fui-checkbox label {
    align-items: center;
    display: flex;
    min-height: 40px;
  }
  .fui-i .fui-input {
    font-size: 24px;
  }
}
@media (min-width: 800px) {
  .fui-i {
    --fui-label-font-size: 0.87vw;
    --fui-label-line-height: 1.22vw;
    --fui-label-margin: 0vw;
    --fui-input-font-size: 1.05vw;
    --fui-input-line-height: 1.22vw;
    --fui-input-width: 100%;
    --fui-input-padding: 0.44vw;
    --fui-btn-font-size: 1.39vw;
    --fui-btn-line-height: 1.39vw;
    --fui-check-margin-bottom: 0.44vw;
    --fui-check-font-size: 0.87vw;
    --fui-check-line-height: 0.87vw;
    --fui-check-label-width: 1.74vw;
    --fui-check-label-height: 1.74vw;
    --fui-check-label-padding-left: 2.4vw;
    --fui-check-label-line-height: 1.74vw;
  }
  .fui-i .iti__selected-dial-code {
    line-height: 1.05vw;
    font-size: 1.05vw;
  }
  .fui-i .fui-type-checkboxes[data-field-handle=angebote] .fui-input-container {
    margin-top: 1.31vw;
    columns: 2;
  }
}