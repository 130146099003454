.scrollpfeil {

    &__knopf {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    &__svg {
        height: 100%;
        width: 100%;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .scrollpfeil {
        @include abstand($layout, margin, alle, 45, 0, 0, 0);
        background: none;
        bottom: 0;
        border: none;
        cursor: pointer;
        display: block;
        height: groesse($layout, 55);
        left: 0;
        padding: 0;
        width: groesse($layout, 27);

        &--desktop {
            display: none;
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .scrollpfeil {

        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: groesse($layout, 54);

        &--smartphone {
            display: none;
        }

        &__abstand {
            height: 100vh;
        }

        &__knopf {
            @include abstand($layout, margin, alle, 0, 0, 52 - 10, ((62 - 10) - 200));
            @include abstand($layout, padding, alle, 10, 10, 10, 10);
            height: groesse($layout, 100);
            position: sticky;
            top: calc(100vh - groesse($layout, 100));
            width: groesse($layout, 54);
        }

    }
}