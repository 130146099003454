.text {

    hyphens: auto;
    &__bild-img {
        border-radius: 100%;
        overflow: hidden;
        width: 100%;
    }

    a {
        color: var(--abschnittsfarbe);
        text-decoration: none;
        font-weight: 700;
        
        &:hover {
            color: black;
        }
    }

    p, h4 {
        &:first-child {
            margin-top: 0;
        }
        
        &:last-child {
            margin-bottom: 0;
        }
    }

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .text {
        @include abstand($layout, margin, alle, 45, 0, 45);
        width: 100%;
        
        &__text {
            @include abstand($layout, margin, alle, 45, 0, 45 - 27, 0);
            @include schriftart($layout, 20, 24);

            ul {
                @include abstand($layout, margin, alle, 24, 0, 24, 0);
            }

            li {
                @include abstand($layout, padding, left, 25);
                
                &::before {      
                    top: groesse($layout, -1);
                }
            }

            p {
                @include abstand($layout, margin, bottom, 27);
            }

            h4 {
                @include abstand($layout, margin, bottom, 27);
            }
        }

        &__link {
            @include abstand($layout, margin, alle, (50 - 28), 0, (50 - 28), 0);
            @include schriftart($layout, 36, 36, 700);
        }

        h4 {
            margin-top: groesse($layout, 46 - 24);
        }
    }
}


@include mediaquery(desktop) {
    
    $layout: desktop;
    
    .text {
        @include abstand($layout, margin, alle, 52, -22.5, 52, -22.5);
        align-items: flex-start;
        display: flex;
        width: calc(100% + groesse($layout, map-get($spaltenabstaende, $layout)));
        
        &__text,
        &__bild {
            @include abstand($layout, padding, alle, 0, 22.5, 0, 22.5);
        }
        
        &__text {
            @include schriftart($layout, 24, 28);
            width: calc(100% / 12 * 8);

            ul {
                @include abstand($layout, margin, alle, 28, 0, 28  - 27, 0);
            }

            li {
                @include abstand($layout, padding, left, 25);
                
                &::before {      
                    top: groesse($layout, -1);
                }
            }

            p {
                @include abstand($layout, margin, bottom, 27);
            }

            h4 {
                @include abstand($layout, margin, bottom, 27);
            }
        }
        
        &__bild {
            width: calc(100% / 12 * 4);
        }
        
        &__link {
            @include abstand($layout, margin, alle, (60 - 28), 0, (60 - 28), 0);
            @include schriftart($layout, 52, 58, 700);
        }

        h4 {
            margin-top: groesse($layout, 53 - 28);
        }
    }
}