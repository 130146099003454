.call-to-action-button {
    font-weight: 700;
    position: relative;
    
    &__button {
        background-color: var(--abschnittsfarbe);
        color: #fff;
        display: inline-block;
        position: relative;
        text-decoration: none;
        
        &:hover {
            background-color: black;
        }
    }


    &__svg {
        fill: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .call-to-action-button {
        @include abstand($layout, margin, alle, -40 + 30, 0, 40, 0);
        @include schriftart($layout, 24, 26, 700);
        
        &__button {
            @include abstand($layout, padding, alle, 8, 11 + 28 + 8, 7, 15);
            border-radius: groesse($layout, 24);
        }

        &__svg {
            height: groesse($layout, 13);
            width: groesse($layout, 28);
            right: groesse($layout, 11);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .call-to-action-button {
        @include abstand($layout, margin, alle, -88 + 60, 0, 88, 0);
        @include schriftart($layout, 32, 34);
        
        &__button {
            @include abstand($layout, padding, alle, 12, 25 + 34 + 16, 10, 25);
            border-radius: groesse($layout, 35);
        }

        &__svg {
            height: groesse($layout, 18);
            width: groesse($layout, 34);
            right: groesse($layout, 25);
        }
    }
}