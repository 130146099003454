// Stellt das Box-Sizing von content-box auf border-box
// padding und border sind dadurch Teil jeder Grössenangabe
html {
    box-sizing: border-box;
}
*, *:after, *:before { 
	box-sizing: border-box;
}

body {
    margin: 0 auto;

    min-height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;

    // Alle Unterstreichungen bei Unterlängen unterbrechen
    text-decoration-skip-ink: auto;

    // Font Smoothing
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; 

    // Transition erst nach dem Laden der Seite anwenden
    &.preload * {
        // animation-duration: 0s !important;
        transition: transform 0s !important;
    }

    font-family: 'Source Serif Pro', serif;

    &[data-site="andreasFleischlin"] {
        background-color: map-get($farben, fleischlinGruen);
    }

    &[data-site="wiederInsLeben"] {
        background-color: map-get($farben, wiederInsLebenBlau);
    }

    &[data-site="bleibenOderGehen"] {
        background-color: map-get($farben, bleibenOderGehenOrange);
    }
}

#seite {

    // body Hintergrundfarbe als Pseudoelement, damit mix-blend-mode mit der Hintergrundfarbe funktioniert
    &:after {

        background-color: map-get($farben, hintergrund);
        content: "";
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: -100;
        
    }

}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
    position: relative;
    
    &::before { 
        content: "–";
        left: 0;
        position: absolute;      
    }
}


// Damit keine SVG's im Smartphone abgeschnitten werden. 
svg {
    padding: 1px 1px 1px 1px;
}

// Fokus Outline entfernen, wenn nicht per Keyboard gesetzt
// https://css-tricks.com/keyboard-only-focus-styles/
// Fokussiert, aber nicht mit der Tastatur
*:focus:not(:focus-visible) {
    outline: none !important;
}
// Mit der Tastatur fokussiert
*:focus-visible { 
    outline: 1px solid map-get($farben, braun-1);
 }

// padding-bottom Trick für Bilder
.bild-verhaeltnis {
    
    position: relative;
    display: block;
    height: 0;
    width: 100%;

    &.lazyloaded {

        background-color: white;
        transition: background-color 500ms ease-in;

    }

}

.bild-verhaeltnis img,
.bild-verhaeltnis picture {
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;

}

// Alt Text unsichtbar machen, aber für Screenreader lesbar halten
// Bilder als Block statt als Inline anzeigen. Das löst das Problem von unerwünschten Pixeln unterhalb des Bildes.
img {

    color: transparent;
    display: block;
    line-height: 1em;
    text-indent: -9999px;

}

// Layzload Bilder erst nach dem Laden einblenden
.lazyload,
.lazyloading {
	opacity: 0;
}

.lazyloaded {
	opacity: 1;
    /*  transition is applied to lazyloaded, not to lazyload */
	transition: opacity 500ms ease-in;
}