.karte {

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .karte {
        
        &__container {
            @include abstand($layout, margin, alle, 45, 0, 45, 0);
            width: 100%;
        }
        
        &__karte {
            height: groesse($layout, 440);
        }   

    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .karte {

        &__container {
            @include abstand($layout, margin, alle, 165, 0, 0, 0);
            width: 100%;
        }
        
        &__karte {
            height: groesse($layout, 634);
        }
        
    }

}