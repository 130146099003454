.intro {

    &__haupttext {
        z-index: 1;
    }

    &__titel, &__untertitel {
        color: #fff;
    }

    &__kreis,
    &__einstieg-bild {
        border-radius: 100%;
        overflow: hidden;
    }
    
    &__kreis {
        &--hauptbild {
            position: relative;
        }

        &--hover-bild.macro-bild {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            display: none;
        }

        &:hover .intro__kreis--hover-bild {
            display: block;
        }
    }

    &__zitat {
        margin: 0;
    }

    &__zitat-quelle {
        color: #000;
    }

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .intro {
        display: flex;
        flex-wrap: wrap;

        &__titel {
            @include abstand($layout, margin, bottom, 10);
            @include schriftart($layout, 34, 34, 700);
        }

        &__untertitel {
            @include schriftart($layout, 20, 24);
        }

        &__bilder {
            @include abstand($layout, margin, alle, 0, -22, 0, -22);
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100vw;
        }
        
        &__bild-startseite {
            width: 100%;

            &--desktop-bild {
                display: none !important;
            }
        }
        
        &__haupttext {
            @include abstand($layout, margin, alle, 0, 22, 0, 22);
            position: absolute;
            top: groesse($layout, 25);
            left: 0;
        }

        &__kreise {
            @include abstand($layout, margin, alle, 0, -7.5, 0, -7.5 + 22);
            display: flex;
            position: absolute;
            bottom: -12vw;
            left: 0;
            width: 100%;
        }
        
        &__kreis {
            &--hauptbild {
                @include abstand($layout, margin, alle, 0, 7.5, 0, 7.5);
                width: spalten($layout, 4, 8, 3, 0);

                &:nth-child(n+3) {
                    display: none;
                }
            }
            
            &--microsite {
                position: absolute;
                right: groesse($layout, 22);
                bottom: -12vw;
                width: spalten($layout, 4, 8, 3, 0);
            }
        }

        &__lead {
            @include abstand($layout, margin, alle, 69, 0, 40, 0);
            @include schriftart($layout, 20, 24);
            width: 100%;
        }

        &__einstieg {
            @include abstand($layout, margin, top, 69 + 15);
        }

        &__einstieg-text {
            @include abstand($layout, margin, bottom, 45);
            @include schriftart($layout, 20, 24, 400);
        }

        &__einstieg-autor {
            @include abstand($layout, margin, bottom, 30);
            @include schriftart($layout, 20, 20, 700);
        }

        &__einstieg-bild {
            width: spalten($layout, 4, 10, 0, 0);
        }
        
        &__aktuell {
            display: flex;
            flex-direction: column;
        }

        &__smartphone-logo {
            @include abstand($layout, padding, alle, 7.5, 7.5, 7.5, 7.5);
            width: calc(100% / 8 * 4);
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
        
        &__smartphone-logo-svg {
            width: groesse($layout, 55);
            height: groesse($layout, 81);
            display: flex;
        }

        .call-to-action-button {
            
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .intro {

        body[data-site="wiederInsLeben"] & {
            @include abstand($layout, margin, bottom, 265 - 52);
        }

        &__haupttext {
            position: absolute;
            top: groesse($layout, 77);
            left: groesse($layout, 200);
        }

        &__titel {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 100, 100, 700);
        }

        &__untertitel {
            @include schriftart($layout, 52, 58);
        }

        &__bilder {
            @include abstand($layout, margin, top, -63);
            position: relative;
            margin-left: groesse($layout, -200);
        }

        &__bild-startseite {
            width: 100%;

            &--smartphone-bild {
                display: none !important;
            }
        }

        &__kreise {
            @include abstand($layout, margin, alle, 0, -20, 0, -20 + 200);
            display: flex;
            position: absolute;
            bottom: calc(0px - groesse($layout, 70));
            left: 0;
            width: 100%;
        }
        
        &__kreis {           
            &--hauptbild {
                @include abstand($layout, margin, alle, 0, 20, 0, 20);
                width: spalten($layout, 2, 12, 0, 1);
            }
            
            &--microsite {
                position: absolute;
                right: calc(spalten($layout, 1, 12, 4, 1));
                bottom: groesse($layout, -100);
                width: spalten($layout, 4, 12, 0, 0);
            }
        }

        &__lead {
            @include abstand($layout, margin, alle, 138, 0, 138, 0);
            width: 100%;
        }

        &__einstieg {
            @include abstand($layout, margin, alle, 68 + 70, 0, 88, 0);
            width: spalten($layout, 10, 12, 0, 0);
            display: block;
        }

        &__einstieg-text {
            // 88 gesammte hoehe - .intro__bilder--hauptseite (margin-bottom)
            @include abstand($layout, margin, bottom, 94);
            @include schriftart($layout, 52, 58, 400);
        }

        &__einstieg-autor {
            @include abstand($layout, margin, bottom, 52);
            @include schriftart($layout, 35, 35, 700);
        }

        &__einstieg-bild {
            width: spalten($layout, 2, 10, 0, 1);
        }

        &__aktuell {
            display: flex;
            flex-wrap: wrap;
        }
        
        &__aktuell-intro {
            @include abstand($layout, margin, alle, 245, 0, 65, 0);
            @include schriftart($layout, 52, 52, 700);
            color: map-get($farben, fleischlinGruen);
            width: 100%;
        }

        &__aktuell-spaltenabstand {
            width: groesse($layout, 80);
        }

        &__aktuell-strich {
            width: groesse($layout, 107);
            height: groesse($layout, 53.5);
        }

        &__aktuell-container {
            width: spalten($layout, 3.35, 12, 0, 1);
        }
        
        &__aktuell-datum {
            @include schriftart($layout, 24, 38, 700);
        }
        
        &__aktuell-titel {
            @include abstand($layout, margin, bottom, 5);
            @include schriftart($layout, 36, 38, 700);
        }
        
        &__aktuell-text {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 20, 26, 300);
        }

        &__smartphone-logo {
            display: none;
        }

        &__lead {
            @include abstand($layout, margin, top, 138);
            @include schriftart($layout, 52, 58, 300);
        }

        &__text {
            @include abstand($layout, margin, alle, 50, 0, 50, 0);
            @include schriftart($layout, 24, 28, 300);
        }

        &__zitat {
            @include abstand($layout, margin, alle, 88, 0, 88, 0);
            @include schriftart($layout, 34, 36, 700);
        }
        
        &__zitat-quelle {
            @include schriftart($layout, 20, 26, 300);
            @include abstand($layout, margin, top, 15);
        }
        
        .call-to-action-button {
            @include abstand($layout, margin, top, -138 + 60);
        }
    }
}