.zwischentitel {
    width: 100%;

    body[data-site="wiederInsLeben"] & {
        color: map-get($farben, flaechen);
    }

    body[data-site="wiederInsLeben"] & {
        color: map-get($farben, wiederInsLebenMenueText);
    }

    body[data-site="bleibenOderGehen"] & {
        color: map-get($farben, bleibenOderGehenOrange);
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .zwischentitel {
        @include abstand($layout, margin, alle, 45, 0, 45);
        @include schriftart($layout, 35, 40, 600);
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .zwischentitel {
        @include abstand($layout, margin, alle, 52, 0, 52);
        @include schriftart($layout, 52, 58, 600);
        
        body[data-site="andreasFleischlin"] & {
            @include abstand($layout, margin, alle, 165, 0, 52);
        }
    }

}