.logo {
    &__svg {
        width: 100%;
        height: 100%;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .logo {
        display: flex;
        justify-content: flex-end;
        @include abstand($layout, margin, alle, 11, 0, 22, 0);

        &__svg--smartphone {
            height: groesse($layout, 81);
            width: groesse($layout, 56);
        }

        &__svg--desktop {
            display: none;
        }

    }

}


@include mediaquery(desktop) {

    $layout: desktop;
        
        .logo {
            @include abstand($layout, padding, bottom, 52);
            @include abstand($layout, margin, right, 52);
            bottom: 0;
            position: fixed;
            right: 0;
            width: groesse($layout, 166);

            &__svg--smartphone {
                display: none;
            }
        }
}