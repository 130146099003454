.header {

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .header {
        &__logo {
            position: absolute;
            top: groesse($layout, 15);
            left: spalten($layout, 0, 8, 0, 2.5);
            width: groesse($layout, 56);
            height: groesse($layout, 105);
        }
        
        &__logo-svg {
            width: 100%;
            height: 100%;
        }

        &__logo-svg {
            fill: white;
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .header {
        &__logo {
            display: none;
        }
    }

}