.menue {
    align-items: flex-start;
    flex-direction: column;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 100;
    
    &__shortlink {
        display: none;
    }

    &__link {
        align-items: flex-start;
        display: block;
        transform-origin: top left;
        transform: rotate(-19deg);
        text-decoration: none;

        body[data-site="wiederInsLeben"] & {
            color: map-get($farben, wiederInsLebenMenueText);
        }

        body[data-site="bleibenOderGehen"] & {
            color: map-get($farben, bleibenOderGehenOrange);
        }

        body[data-site="andreasFleischlin"] & {
            color: black;
        }
    }

    &__link:hover {
        body[data-site="wiederInsLeben"] & {
        color: black
        }
    }    
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .menue {
        @include abstand($layout, padding, alle, 150, 22, 22, 22);
        @include schriftart($layout, 26, 38, 700);
        background-color: white;
        left: 0;
        height: calc((var(--vh, 1vh) * 100));
        overflow-y: scroll;
        // transform: translateX(100%);
        display: flex;
        flex-direction: row;
        visibility: hidden;
        width: 100%;

        body[data-menue-aktiv="true"] & {
        // body[data-site="andreasFleischlin"][data-menue-aktiv="true"] & {
            display: flex;
            justify-content: space-between;
        }

        body[data-menue-aktiv="true"] & {
            // transform: translateX(0);
            visibility: visible
        }

        &__link {

            &--1 {
                @include abstand($layout, margin, top, 30);
            }
        }

        &__hamburger {
            // +1 wegen svg padding
            @include abstand($layout, padding, alle, 16 + 1, 22 + 1, 16 + 1, 22 + 1);
            position: fixed;
            right: 0;
            top: 0;
            height: groesse($layout, 53 + (16 * 2));
            width: groesse($layout, 55 + (22 * 2));
            z-index: 101;
            cursor: pointer;
        }

        &__kurzzugriffe-smartphone {
        // body[data-menue-aktiv="true"][data-site="andreasFleischlin"] &__svgs {
            @include abstand($layout, margin, alle, 0, -10, 0, -10);
            display: none;
            left: spalten($layout, 0, 8, 0, 2.5);
            position: absolute;
            top: groesse($layout, (calc(0 + 16 + 1)));
            z-index: 110;
            
            body[data-menue-aktiv="true"] & {
                display: flex;
            }
        }

        &__kurzzugriff-smartphone {
            @include abstand($layout, margin, alle, -5, 5, 0, 5);
            @include abstand($layout, padding, alle, 5, 5, 5, 5);
            
            &--at {
                width: calc(groesse($layout, 33.5) + 10px);
                height: calc(groesse($layout, 33.9) + 10px);
            }
            
            &--pin {
                width: calc(groesse($layout, 28.5) + 10px);
                height: calc(groesse($layout, 41) + 10px);
            }        
    
            &--instagram {
                width: calc(groesse($layout, 34.75) + 10px);
                height: calc(groesse($layout, 34.8) + 10px);
            }
    
            &--linkedin {
                width: calc(groesse($layout, 34.7) + 10px);
                height: calc(groesse($layout, 34.7) + 10px);
            }
        }

        &__kurzzugriff-smartphone-svg {
            width: 100%;
            height: 100%;
        }
        

        &__logo {
            align-self: flex-end;
            height: groesse($layout, 203);
            margin-top: auto;
            width: groesse($layout, 109);
        }

        &__logo-svg {
            height: 100%;
            width: 100%;
        }

        &__kurzzugriffe-desktop {
            display: none;
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .menue {
        @include abstand($layout, padding, alle, 25, 0, 0, 0);
        @include schriftart($layout, 28, 37, 700);
        right: 0;
        width: groesse($layout, 425);
        height: 100%;

        &__links {
            .body[data-site="andreasFleischlin"] & {
                // Minus 30 wegen erstem Link
                @include abstand($layout, margin, top, 105 - 30);
                order: 2;
            }
            
            .body[data-site="wiederInsLeben"] &, .body[data-site="bleibenOderGehen"] & {
                // Minus 30 wegen erstem Link
                @include abstand($layout, margin, top, 177 - 30);
            }
        }

        &__link {
            &--1 {
                @include abstand($layout, margin, top, 30);
            }
        }

        &__hamburger {
            display: none;
        }

        &__logo {
            margin-top: groesse($layout, 77);
            height: groesse($layout, 375);
            width: groesse($layout, 201);

            .body[data-site="andreasFleischlin"] & {
                order: 1;
            }
            
            .body[data-site="wiederInsLeben"] &, .body[data-site="bleibenOderGehen"] &  {
                height: groesse($layout, 166);
                margin-top: auto;
                width: groesse($layout, 98);
            }
        }

        &__logo-svg {
            width: 100%;
            height: 100%;
        }

        &__kurzzugriffe-smartphone {
            display: none;
        }

        &__kurzzugriffe-desktop {
            @include abstand($layout, margin, left, -10);
            @include abstand($layout, padding, alle, 85, 0, 75, -0);
            align-items: flex-end;
            display: flex;
            // height: 100%;
            margin-right: groesse($layout, 25);

            .body[data-site="andreasFleischlin"] & {
                flex: 1;
                order: 3;
            }
        }

        &__kurzzugriffe-desktop-container {
            display: flex;
        }

        &__kurzzugriff-desktop-svg {
            @include abstand($layout, margin, alle, 0, 10, 0, 10);
            height: groesse($layout, 34);
            width: groesse($layout, 34);
        }
    }
}