.signatur {

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text a {
        color: black;
        text-decoration: none;

        &:hover {
            color: map-get($farben, 'buttons');
        }
    }

    &__instagram {
        color: map-get($farben, 'buttons');
        fill: map-get($farben, 'buttons');
        font-weight: 700;
        text-decoration: none;

        &:hover {
            color: black;
            fill: black;
        }
    }

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .signatur {
        @include abstand($layout, margin, alle, 145, 0, 45, 0);
        @include schriftart($layout, 20, 24);
        
        p {
            @include abstand($layout, margin, alle, 24, 0, 24, 0);
        }
        
        &__instagram--svg {
            @include abstand($layout, margin, top, 30);
            height: groesse($layout, 38);
            width: groesse($layout, 35);   
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .signatur {
        @include abstand($layout, margin, alle, 165, 0, 265);
        @include schriftart($layout, 24, 28);
        margin-top: groesse($layout, 265);

        p {
            @include abstand($layout, margin, alle, 28, 0, 28, 0);
        }

        &__instagram--svg {
            @include abstand($layout, margin, top, 30);
            height: groesse($layout, 50);
            width: groesse($layout, 50);   
        }

    }

}