.aktuell {
    a {
        color: var(--abschnittsfarbe);
        text-decoration: none;
        font-weight: 700;
        
        &:hover {
            color: black;
        }
    }

    &__bild {
        width: 75%;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .aktuell {
        display: flex;
        flex-direction: column;


        &__intro {
            @include abstand($layout, margin, alle, 135, 0, 45, 0);
            @include schriftart($layout, 36, 36, 700);
            color: map-get($farben, fleischlinGruen);
            width: 100%;
        }

        &__strich {
            width: groesse($layout, 57.3);
            height: groesse($layout, 28.65);
        }

        &__strich-svg {
            &--smartphone {
                display: block;
            }

            &--desktop {
                display: none;
            }
        }


        &__container {
            @include abstand($layout, margin, alle, 0, 0, 45, 0);
        }

        &__titel {
            @include abstand($layout, margin, alle, 7, 0, 15, 0);
            @include schriftart($layout, 28, 28, 700);
        }

        &__text {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 20, 26, 300);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .aktuell {
        display: flex;
        flex-wrap: wrap;

        &__intro {
            @include abstand($layout, margin, alle, 245, 0, 65, 0);
            @include schriftart($layout, 52, 52, 700);
            color: map-get($farben, fleischlinGruen);
            width: 100%;
        }

        &__spaltenabstand {
            width: groesse($layout, 80);
        }

        &__strich {
            width: groesse($layout, 107);
            height: groesse($layout, 52);
        }

        &__strich-svg {
            &--smartphone {
                display: none;
            }
            
            &--desktop {
                display: block;
            }
        }

        &__container {
            width: spalten($layout, 3.35, 12, 0, 1);
        }

        &__titel {
            @include abstand($layout, margin, alle, 3, 0, 15, 0);
            @include schriftart($layout, 36, 38, 700);
        }

        &__text {
            @include abstand($layout, margin, bottom, 25);
            @include schriftart($layout, 20, 26, 300);
        }
    }
}