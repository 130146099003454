.zitat {
    margin: 0;

    [data-site="wiederInsLeben"] & {
        color: map-get($farben, wiederInsLebenBlau);
    }

    [data-site="bleibenOderGehen"] & {
        color: map-get($farben, bleibenOderGehenOrange);
    }

    &__quelle {
        color: #000;
    }
}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .zitat {
        @include abstand($layout, margin, alle, 40, 0, 40, 0);
        @include schriftart($layout, 24, 26, 700);
        
        &__quelle {
            @include schriftart($layout, 20, 24, 300);
            @include abstand($layout, margin, top, 10);
        }
    }
}


@include mediaquery(desktop) {

    $layout: desktop;

    .zitat {
        @include abstand($layout, margin, alle, 88, 0, 88, 0);
        @include schriftart($layout, 34, 36, 700);
        width: spalten($layout, 6, 12);
        
        [data-site="wiederInsLeben"] & {
            color: map-get($farben, wiederInsLebenBlau);
        }

        [data-site="bleibenOderGehen"] & {
            color: map-get($farben, bleibenOderGehenOrange);
        }
        
        &__quelle {
            @include schriftart($layout, 20, 26, 300);
            @include abstand($layout, margin, top, 15);
        }
    }
}