.button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: 'Source Serif Pro', serif;
    padding: 0;
    text-align: left;
    text-decoration: none;
    
    &:hover {
        color: black;
        fill: black;
    }
    
    &__pfeil {
        
        body[data-site="wiederInsLeben"] & {
            fill: map-get($farben, wiederInsLebenBlauButton);
        }

        body[data-site="bleibenOderGehen"] & {
            fill: map-get($farben, bleibenOderGehenOrange);
        }
    }
}




@include mediaquery(smartphone) {

    $layout: smartphone;

    .button {
        @include schriftart($layout, 28, 30, 700);

        &__pfeil {
            height: groesse($layout, 17);
            width: groesse($layout, 35);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .button {
        @include schriftart($layout, 32, 34, 700);

        &__pfeil {
            height: groesse($layout, 17);
            width: groesse($layout, 35);
        }
    }

}