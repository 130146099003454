.intro-abschnitte {

}


@include mediaquery(smartphone) {

    $layout: smartphone;

    .intro-abschnitte {
        display: flex;
        flex-direction: column;

        &__titel {
            @include abstand($layout, margin, alle, 28, 0, 15, 0);
            @include schriftart($layout, 34, 34, 700);
        }

        &__untertitel {
            @include schriftart($layout, 20, 24);
        }
    }

}


@include mediaquery(desktop) {

    $layout: desktop;

    .intro-abschnitte {
        @include abstand($layout, margin, bottom, 151);

        .body--intro &,
        body[data-site="wiederInsLeben"] & {
            @include abstand($layout, margin, bottom, 265 - 52);
        }

        &__titel {
            @include abstand($layout, margin, bottom, 20);
            @include schriftart($layout, 100, 100, 700);
        }

        &__untertitel {
            @include schriftart($layout, 52, 58);
        }
    }

}