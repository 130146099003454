.fui-i {

    body[data-site="wiederInsLeben"] & .fui-type-multi-line-text {
        display: none;
    }

    body[data-site="andreasFleischlin"] & .fui-type-checkboxes[data-field-handle="angebote"] {
        display: none;
    }

    .fui-checkbox input:checked + label::before {
        background-image: url('/bausteine/formie/formie__checkbox.svg');
        background-size: 100%;
    }

    .fui-checkbox input:checked + label::before, .fui-radio input:checked + label::before {
        border-color: black;
        background-color: transparent;
    }

    .fui-submit {
        body[data-site="wiederInsLeben"] & {
            color: map-get($farben, wiederInsLebenBlauButton);
        }
        
        body[data-site="andreasFleischlin"] & {
            color: #B8C4B6;
        }

        body[data-site="bleibenOderGehen"] & {
            color: map-get($farben, bleibenOderGehenOrange);
        }

        &:hover {
            color: black;
        }
    }

    body[data-site="wiederInsLeben"] & .fui-submit {
        background-image: url('/bausteine/formie/formie__pfeil-blau.svg');
        background-repeat: no-repeat;
        background-position: right center;
    }

    body[data-site="andreasFleischlin"] & .fui-submit {
        background-image: url('/bausteine/formie/formie__pfeil-gruen.svg');
        background-repeat: no-repeat;
        background-position: right center;
    }

    body[data-site="bleibenOderGehen"] & .fui-submit {
        background-image: url('/bausteine/formie/formie__pfeil-orange.svg');
        background-repeat: no-repeat;
        background-position: right center;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .fui-i {
        
        
        .fui-type-checkboxes[data-field-handle="newsletter"] {
            @include abstand($layout, margin, top, 52);
        }
        
        &__bestaetigung {
            @include abstand($layout, padding, alle, 170, 22, 0, 22);
            
            p {
                @include schriftart($layout, 20, 24);
                @include abstand($layout, margin, alle, 24, 0, 24, 0);
            }
        }

        .fui-submit {
            padding-right: groesse($layout, 40);
            background-size: groesse($layout, 24.35), auto;
        }

    }
}


@include mediaquery(desktop) {
    $layout: desktop;

    .fui-i {
        width: spalten($layout, 6, 12, 0, 0);
        
        .fui-type-checkboxes[data-field-handle="newsletter"] {
            @include abstand($layout, margin, top, 52);
        }

        .fui-submit {
            padding-right: groesse($layout, 45);
            background-size: groesse($layout, 30), auto;
        }
    }
}